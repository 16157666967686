import React from 'react'

import img1 from './img/im1.png';
import img2 from './img/im2.png';
import img3 from './img/im3.png';
import img4 from './img/im4.png';
import { Link } from 'react-router-dom';

import imga11 from './img/img11.png';
import img12 from './img/img12.png';
import img13 from './img/img13.png';
import img14 from './img/img14.png';
import logo from './images/newlogo.png'

import img21 from './img/img21.png';
import img22 from './img/img22.png'


function Cex() {
    return (
        <div>
            <div className='bg-c'>
                <div className='black-bg'>
                    <div className='container'>
                        <h2 className='text-center text-white'>NOW LISTED ON MAJOR CRYPTO EXCHANGES (CEX)!</h2>
                        <div className='row main-img'>
                            <div className='col-sm-6'>
                                <img src={img1} alt='' className='img-fluid' />
                                <p className='text-right'><Link
                                    to='https://www.biconomy.com/exchange/NGTG$$_USDT'>
                                    https://www.biconomy.com/exchange/NGTG$$_USDT
                                </Link>
                                </p>
                            </div>
                            <div className='col-sm-6'>
                                <img src={img2} alt='' className='img-fluid' />
                                <p className='text-right'><Link
                                    to='https://www.xt.com/en/trade/ngtg_usdt'>
                                    https://www.xt.com/en/trade/ngtg_usdt
                                </Link>
                                </p>
                            </div>
                            <div className='col-sm-6'>
                                <img src={img3} alt='' className='img-fluid' />
                                <p className='text-right'><Link
                                    to='https://dex-trade.com/spot/trading/NGTG$$USDT?interface=classic'>
                                    https://dex-trade.com/spot/trading/NGTG$$USDT?interface=classic
                                </Link>
                                </p>
                            </div>
                            <div className='col-sm-6'>
                                <img src={img4} alt='' className='img-fluid' />
                                <p className='text-right'><Link
                                    to='https://uzx.com/#/exchange/ngtg_usdt'>
                                    https://uzx.com/#/exchange/ngtg_usdt
                                </Link>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='bg-2 '>
                <div className='bg-dark1 padding-top'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-sm-4'>
                                <img src={logo} alt='' style={{ height: '60px' }} />
                            </div>
                            <div className='col-sm-4'>
                                <h4 className='text-white'>COMING SOON </h4>
                            </div>
                            <div className='col-sm-4'>
                                <h4 className='text-white'>#RWATOKEN</h4>
                            </div>
                        </div>
                        <div className='row live-track-img'>
                            <div className='col-sm-6'>
                                <img src={imga11} alt='' />
                            </div>
                            <div className='col-sm-6'>
                                <img src={img12} alt='' />
                            </div>
                            <div className='col-sm-6'>
                                <img src={img13} alt='' />
                            </div>
                            <div className='col-sm-6'>
                                <img src={img14} alt='' />
                            </div>
                        </div>
                        {/* <h4 className='text-center text-white'>OUR MARKET MAKERS PARTNERSHIPS
                        </h4>
                        <div className='row live-track-img'>
                            <div className='col-sm-6'>
                                <img src={img21} alt='' />
                            </div>
                            <div className='col-sm-6'>
                                <img src={img22} alt='' />
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Cex
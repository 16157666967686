import React, { useEffect, useState } from 'react'
import {
    Card, CardHeader, Grid2, TextField, Breadcrumbs, Typography, FormControl, Button, Divider,
    RadioGroup, FormLabel, FormControlLabel, Radio, InputLabel, Input, InputAdornment,
    Autocomplete, OutlinedInput, IconButton,
    Backdrop,
    CircularProgress
} from '@mui/material'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import BasicTable from './BasicTable'
import { AiOutlineOrderedList, AiOutlineUserAdd } from 'react-icons/ai'

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import MuiPhoneNumber from 'material-ui-phone-number';
import axios from 'axios'
import CountrySelect from './CountrySelect'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import dayjs, { Dayjs } from 'dayjs';
import Snackbar, { SnackbarCloseReason } from '@mui/material/Snackbar';


function SubscriptionMaster() {
    const navigate = useNavigate();
    const location = useLocation();

    const [newSubscript, setNewSubscript] = useState({});
    const [type, setType] = useState('');
    const [message, setMessage] = useState('');
    const [open, setOpen] = React.useState(false);

    const [dataList, setDataList] = React.useState([]);
    const [showPassword, setShowPassword] = React.useState(false);
    const [sopen, setSOpen] = React.useState(false);
    const [register_flug, setRegister_flug] = React.useState(false);

    const handleClose = (event, reason
    ) => {
        if (reason === 'clickaway') {
            return;
        }

        setSOpen(false);
    };

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleMouseUpPassword = (event) => {
        event.preventDefault();
    };
    const getContry = (e) => {
        console.log(e)
        setNewSubscript((prev) => ({ ...prev, country: e }))
    }
    const jsDate = (adate) => {
        var date = new Date(adate);
        var day = String(date.getDate()).padStart(2, '0');
        var month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
        var year = date.getFullYear();
        const formattedDate = `${day}-${month}-${year}`;
        return formattedDate;

    }

    const fetchUsers = async () => {
        const listUser = await axios.get(`${process.env.REACT_APP_API_URL}/list-role`)
        const nnData = listUser.data.result;
        var roleList = [];
        for (let i = 0; i < nnData.length; i++) {
            roleList.push({ id: nnData[i].id, label: nnData[i].name })
        }
        setDataList(roleList)
        console.log(listUser.data.result);
    }
    const handalSubmit = async () => {
        if ( !newSubscript.fname || newSubscript.fname === '' ) return alert('Please enter name!');
        if ( !newSubscript.email || newSubscript.email === '') return alert('Please enter email!');
        if (!newSubscript.country || newSubscript.country === '') return alert('Please enter country!');
        if ( register_flug === false) return alert('Please register user category!');
        console.log(newSubscript)
        //return;
        setOpen(true)
        if (newSubscript.id) {
            const submitSubscriptiona = await axios.post(`${process.env.REACT_APP_API_URL}/subscription/update`, newSubscript)
            setOpen(false)
            setMessage('Subscription successfully updated !')
        } else {
            const submitSubscription = await axios.post(`${process.env.REACT_APP_API_URL}/subscription/add`, newSubscript)
            setOpen(false)
            if(submitSubscription.data.error ==1){
                setMessage(submitSubscription.data.message)
                setSOpen(true);
                return
            }else{
                setMessage('Subscription successfully added !')
            }
            
        }
        setSOpen(true);
        setRegister_flug(false)
        setNewSubscript({ age: '', phone: '', contact_person: '', pice_per_token:'',total_num_token:'',
            email: '', fname: '', gender: '', lname: '', password: '', address: '', country: '', role: '', invstamt: '', })
        setTimeout(() => {
            
        }, 300);

    }
    const handalReg = async () => {   //  newSubscript?.invstamt
        if (!newSubscript?.invstamt) {
            return alert('Please enter Investment Amount')
        }
        if (!newSubscript?.pice_per_token) {
            return alert('Please enter Price Amount')
        }
        if (!newSubscript?.role) {
            return alert('Please select User category')
        }
        if (!newSubscript?.total_num_token) {
            return alert('Please enter total number of token!')
        }
        setOpen(true)
        const data2 = {
            sub_type: type, invstamt: newSubscript?.invstamt, pice_per_token: newSubscript?.pice_per_token,
            role: newSubscript?.role, total_num_token: newSubscript?.total_num_token
        };
        const rolereg = await axios.post(`${process.env.REACT_APP_API_URL}/update-token`, data2)
        if (rolereg.data.error==0) {
            setMessage('Token is assigned!')
            setRegister_flug(true)
        }
        //setMessage
        setOpen(false);
        setSOpen(true);
        console.log(data2)
    }

    useEffect(() => {
        fetchUsers();
    }, [])
    useEffect(() => {
        if (location.state) {
            setNewSubscript(location.state);
            setType(location.state.type)
            // newSubscript?.country
            ////setNewSubscript((prev) => ({ ...prev, country: newSubscript?.country }))
        }
        console.log(location)
    }, [location])

    return (
        <div>
            <div className='box'>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" color="inherit" href="/">
                        Dashboard
                    </Link>
                    <Typography sx={{ color: 'text.primary' }}>List</Typography>
                </Breadcrumbs>
            </div>

            <div className='text-inner'>
                <Button variant="contained" color='primary'
                    onClick={() => { navigate('/dashboard/subscription-master-list') }}
                >List
                    <AiOutlineOrderedList size={20} />
                </Button>
                <Card style={{ padding: '10px' }}>
                    <h3 className='text-center'>Subscription Master</h3>
                    <Divider />
                    <hr />
                    <div >
                        <form>
                            <h5>Personal Details</h5>
                            <FormControl sx={{ m: 1, }} variant="outlined">
                                <TextField
                                    label="First Name" size='small' InputLabelProps={{ shrink: true }}
                                    variant="outlined" value={newSubscript.fname}
                                    onChange={(e) => { setNewSubscript((prev) => ({ ...prev, fname: e.target.value })) }}
                                />
                            </FormControl >
                            <FormControl sx={{ m: 1, }} variant="outlined">
                                <TextField
                                    label="Last name" size='small'
                                    variant="outlined" value={newSubscript.lname} InputLabelProps={{ shrink: true }}
                                    onChange={(e) => { setNewSubscript((prev) => ({ ...prev, lname: e.target.value })) }}
                                />
                            </FormControl >
                            <FormControl sx={{ m: 1, }} variant="outlined">
                                <TextField
                                    label="Company name" size='small' InputLabelProps={{ shrink: true }}
                                    variant="outlined" value={newSubscript.company_name}
                                    onChange={(e) => { setNewSubscript((prev) => ({ ...prev, company_name: e.target.value })) }}
                                />
                            </FormControl >
                            <FormControl sx={{ m: 1, }} variant="outlined">
                                <TextField
                                    label="Email" size='small' InputLabelProps={{ shrink: true }}
                                    variant="outlined" value={newSubscript.email}
                                    onChange={(e) => { setNewSubscript((prev) => ({ ...prev, email: e.target.value })) }}
                                />
                            </FormControl >

                            <FormControl sx={{ m: 1, }} variant="outlined">
                                {(newSubscript?.id && newSubscript?.country) ? (
                                    <>
                                        <TextField
                                            label="Company name" size='small' InputLabelProps={{ shrink: true }}
                                            variant="outlined" value={newSubscript?.country}
                                        />
                                    </>
                                ) : (
                                    <>
                                        <CountrySelect selectedCountr={getContry} />
                                    </>
                                )
                                }

                            </FormControl >
                            <FormControl sx={{ m: 1, }} variant="outlined">
                                <TextField
                                    label="Address " size='small' InputLabelProps={{ shrink: true }}
                                    variant="outlined" value={newSubscript.adress}
                                    onChange={(e) => { setNewSubscript((prev) => ({ ...prev, adress: e.target.value })) }}
                                />
                            </FormControl >
                            {/* <FormControl sx={{ m: 1, }} variant="outlined">
                                <TextField
                                    label="Symbol " size='small' InputLabelProps={{ shrink: true }}
                                    variant="outlined" value={newSubscript.symbol}
                                    onChange={(e) => { setNewSubscript((prev) => ({ ...prev, symbol: e.target.value })) }}
                                />
                            </FormControl > */}
                            {/* <FormControl sx={{ m: 1, }} variant="outlined">
                                <TextField
                                    label="Contact person " size='small' InputLabelProps={{ shrink: true }}
                                    variant="outlined" value={newSubscript.contact_person}
                                    onChange={(e) => { setNewSubscript((prev) => ({ ...prev, contact_person: e.target.value })) }}
                                />
                            </FormControl > */}
                            <FormControl sx={{ m: 1, }} variant="outlined"> { }
                                <LocalizationProvider dateAdapter={AdapterDayjs} size='small'>
                                    <DatePicker size='small' defaultValue={dayjs(newSubscript?.date_)} sx={{ width: 225 }}
                                        onChange={(newValue) => { setNewSubscript((prev) => ({ ...prev, date_: jsDate(newValue) })) }}
                                    />
                                    {/* setNewSubscript((prev) => ({ ...prev, date_: newValue })) */}
                                </LocalizationProvider>
                            </FormControl >
                            <FormControl sx={{ m: 1, }} variant="outlined">
                                <TextField
                                    label="Age" size='small' type='number' InputLabelProps={{ shrink: true }}
                                    variant="outlined" value={newSubscript.age}
                                    onChange={(e) => { setNewSubscript((prev) => ({ ...prev, age: e.target.value })) }}
                                />
                            </FormControl >
                            <FormControl sx={{ m: 1, }} variant="outlined">
                                <label> </label>
                                <MuiPhoneNumber defaultCountry={'in'}
                                    variant="outlined" size='small' sx={{ width: 225 }}
                                    onChange={(val) => { setNewSubscript((prev) => ({ ...prev, phone: val })) }}
                                    value={newSubscript?.phone}
                                    placeholder='Phone'
                                />
                            </FormControl >
                            <FormControl component="fieldset">
                                <label>Gender </label>
                                <RadioGroup row aria-label="gender" name="row-radio-buttons-group"
                                    value={newSubscript.gender}
                                    onChange={(e) => { setNewSubscript((prev) => ({ ...prev, gender: e.target.value })) }}>
                                    <FormControlLabel checked={newSubscript?.gender === 'female'} value="female" control={<Radio />} label="Female" />
                                    <FormControlLabel checked={newSubscript?.gender === 'male'} value="male" control={<Radio />} label="Male" />
                                </RadioGroup>
                            </FormControl>
                            <hr />
                            <div className='row'>
                                <div className='col-sm-12'>
                                    <FormControl component="fieldset">
                                        <FormLabel component="legend">Type</FormLabel>
                                        <RadioGroup row aria-label="gender" name="row-radio-buttons-group"
                                            onChange={(e) => { setType(e.target.value) }}>
                                            <FormControlLabel checked={type === 'individual'} value="individual" control={<Radio />} label="Individual" />
                                            <FormControlLabel checked={type === 'business'} value="business" control={<Radio />} label="Business" />
                                        </RadioGroup>
                                    </FormControl>
                                </div>

                                <div className='col-sm-12'>
                                    <FormControl sx={{ m: 1, }} variant="outlined">
                                        <TextField
                                            label="Investment Amount($)" type='number'
                                            size='small' InputLabelProps={{ shrink: true }}
                                            value={newSubscript?.invstamt}
                                            onChange={(e) => { setNewSubscript((prev) => ({ ...prev, invstamt: e.target.value })) }}
                                            InputProps={{
                                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                            }}
                                        />
                                    </FormControl >
                                    {/* Price Per Token */}
                                    <FormControl sx={{ m: 1, }} variant="outlined">
                                        <TextField
                                            label="Price Per Token ($)" type='number'
                                            size='small' InputLabelProps={{ shrink: true }}
                                            value={newSubscript?.pice_per_token}
                                            onChange={(e) => { setNewSubscript((prev) => ({ ...prev, pice_per_token: e.target.value })) }}
                                            InputProps={{
                                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                            }}
                                        />
                                    </FormControl >
                                    <FormControl sx={{ m: 1, }} variant="outlined">
                                        <Autocomplete
                                            disablePortal
                                            size='small'
                                            value={newSubscript?.role}
                                            options={dataList}
                                            onChange={(e, newValue) => { setNewSubscript((prev) => ({ ...prev, role: newValue?.id })) }}
                                            sx={{ width: 200 }}
                                            renderInput={(params) => <TextField {...params} label="User Category" />}
                                        />
                                    </FormControl >
                                    <FormControl sx={{ m: 1, }} variant="outlined">
                                        <TextField
                                            label="total Number of Token" type='number'
                                            size='small' InputLabelProps={{ shrink: true }}
                                            value={newSubscript?.total_num_token}
                                            onChange={(e) => { setNewSubscript((prev) => ({ ...prev, total_num_token: e.target.value })) }}
                                        />
                                    </FormControl >
                                    <FormControl sx={{ m: 1, }} variant="outlined">
                                        {register_flug ?(
                                            <Button variant="contained" size="large" color='success'>Registered</Button>
                                        ):(
                                            <Button variant="contained" size="large" onClick={handalReg}>Register</Button>
                                        )}
                                        {/* <Button variant="contained" size="large" onClick={handalReg}>Register</Button> */}
                                    </FormControl>
                                    {/* <div>
                                            <BasicTable />
                                        </div> */}
                                </div>

                                <div className='col-sm-12'>
                                    <hr />

                                    <FormControl sx={{ m: 1, }} variant="outlined">
                                        <TextField
                                            label="Email" size='small' type='email'
                                            variant="outlined" value={newSubscript.email}
                                            onChange={(e) => { setNewSubscript((prev) => ({ ...prev, email: e.target.value, type: type })) }}
                                        />
                                    </FormControl >

                                    <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
                                        <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                                        <OutlinedInput
                                            id="outlined-adornment-password"
                                            type={showPassword ? 'text' : 'password'}
                                            size='small'
                                            value={newSubscript.password}
                                            onChange={(e) => { setNewSubscript((prev) => ({ ...prev, password: e.target.value })) }}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={handleClickShowPassword}
                                                        onMouseDown={handleMouseDownPassword}
                                                        onMouseUp={handleMouseUpPassword}
                                                        edge="end"
                                                    >
                                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                            label="Password"
                                        />
                                    </FormControl>
                                    <br />
                                    {newSubscript.id ? (
                                        <Button variant="contained" color='warning' style={{ width: '100%' }}
                                            onClick={handalSubmit}>Update
                                            <AiOutlineUserAdd />
                                        </Button>
                                    ) : (
                                        <Button variant="contained" style={{ width: '100%' }}
                                            onClick={handalSubmit}>Create
                                            <AiOutlineUserAdd />
                                        </Button>
                                    )}

                                    <Snackbar
                                        open={sopen}
                                        autoHideDuration={3000}
                                        onClose={handleClose}
                                        severity="success"
                                        message={message}
                                    />
                                    <Backdrop
                                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                                        open={open}>
                                        <CircularProgress color="inherit" />
                                    </Backdrop>

                                </div>

                            </div>
                        </form>
                    </div>
                </Card>
            </div >
        </div >
    )
}

export default SubscriptionMaster
import { Visibility, VisibilityOff } from '@mui/icons-material'
import { Grid2, IconButton, InputAdornment, TextField, FormControl, InputLabel, Box, OutlinedInput, Button, Backdrop, CircularProgress } from '@mui/material'
import axios from 'axios';
import React, { useState } from 'react'
import { TbBrandGoogleFilled } from 'react-icons/tb';
import { Link, useNavigate } from 'react-router-dom';
import ReCAPTCHA from "react-google-recaptcha";
import logo from './images/logoa.png'
import MuiPhoneNumber from 'material-ui-phone-number'
import OtpInput from 'react-otp-input';


function SmsConfig() {
    const navigate = useNavigate();
    const [open, setOpen] = React.useState(false);
    const [otp, setOtp] = useState('');

    return (
        <div>
            <Grid2 container >
                <Grid2 size={6}>
                    <div className='log-box'>
                        <div className='center-div'>
                            <img src={logo} alt='logo' className='login-img' />
                            <h4 className='text-center'>OTP verification</h4>

                            <Box className="box">
                                <FormControl sx={{ m: 1, }} variant="outlined">
                                    <MuiPhoneNumber defaultCountry={'in'} label="Phomne number"
                                        variant="outlined" size='small' />
                                </FormControl>
                                <FormControl sx={{ m: 1, }} variant="outlined">
                                    <OtpInput
                                        value={otp}
                                        onChange={setOtp}
                                        numInputs={4}
                                        renderSeparator={<span>-</span>}
                                        renderInput={(props) => <input {...props} className='form-centrol otp' />}
                                    />
                                </FormControl>

                                <FormControl sx={{ m: 1, width: '100%' }} variant="outlined">
                                    <ReCAPTCHA
                                        sitekey="6LevsUUqAAAAALvisRg3xhlTL5bPcVysO6AIXHeu"

                                    />
                                </FormControl>
                                <FormControl sx={{ m: 1, width: '100%' }} variant="outlined">
                                    <Button variant="contained"
                                    >Get OTP</Button>
                                </FormControl>


                            </Box>
                            <Backdrop
                                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                                open={open}>
                                <CircularProgress color="inherit" />
                            </Backdrop>
                        </div>
                    </div>
                </Grid2>
                <Grid2 size={6}>
                    <div className='login-bg'>

                    </div>
                </Grid2>
            </Grid2>
        </div>
    )
}

export default SmsConfig
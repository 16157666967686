import { Visibility, VisibilityOff } from '@mui/icons-material'
import { Grid2, IconButton, InputAdornment, TextField, FormControl, InputLabel, Box, OutlinedInput, Button, Backdrop, CircularProgress } from '@mui/material'
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { TbBrandGoogleFilled } from 'react-icons/tb';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import ReCAPTCHA from "react-google-recaptcha";
import logo from './images/logoa.png'
import OtpInput from 'react-otp-input';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Bus from './admin/Business';


function Business() {
    const params = useParams();
    const navigate = useNavigate()

    const getCheck = async(id)=>{
        const listUser =  await axios.post(`${process.env.REACT_APP_API_URL}/invite/verify`, {code: id})
        if(listUser.data.error==1){
            navigate('/')
            return
           console.log('Invalid code')
        }else{
            console.log('valid code')
        }
        console.log(listUser)
    }

    useEffect(()=>{
        console.log(params)
        if(!params.code){
            navigate('/login')
        }else{
            getCheck(params.code)
        }

        console.log(params)
    },[])
    return (
        <div>
            <Grid2 container >
                <Grid2 size={6} className='regBox'>
                    <Bus checka={1} />
                </Grid2>
                <Grid2 size={6}>
                    <div className='login-bg'>

                    </div>
                </Grid2>
            </Grid2>
        </div>
    )
}

export default Business
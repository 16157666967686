import React, { useEffect, useState } from 'react'
import {
    Card, CardHeader, Grid2, TextField, Breadcrumbs, Typography, FormControl, Button, Divider,
    RadioGroup, FormLabel, FormControlLabel, Radio, InputLabel, Input, InputAdornment, Autocomplete,
    Backdrop,
    CircularProgress,
    IconButton
} from '@mui/material'
import Grid from '@mui/material/Grid2';
import { Link, useLocation, useNavigate } from 'react-router-dom'
import BasicTable from './BasicTable'
import axios from 'axios';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import Switch from '@mui/material/Switch';
import { Label } from '@mui/icons-material';
import CountrySelect from './CountrySelect';
import MuiPhoneNumber from 'material-ui-phone-number';
import { AiOutlineOrderedList } from 'react-icons/ai';
import dayjs, { Dayjs } from 'dayjs';
import Snackbar, { SnackbarCloseReason } from '@mui/material/Snackbar';
import { MdAddCircle, MdDelete, MdSave } from 'react-icons/md';
import MuiAlert from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function TreasuryNew() {
    const navigate = useNavigate();
    const location = useLocation();
    const typeOption = ['Company', 'Association', 'Other'];
    const typecurrency = ['ETH', 'BTC', 'USDT'];
    const [newTreasury, setnewTreasury] = useState({});
    const [newTreasuryOld, setnewTreasuryOld] = useState({});
    const [countryList, setCountryList] = useState([]);

    const [cexcoin, setcexcoin] = useState('');
    const [totalTokenList, setTotalTokenList] = useState('');
    const [listingList, setListingList] = useState([]);

    const [sopen, setSOpen] = React.useState(false);
    const [eopen, setEOpen] = React.useState(false);
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState('');

    const [country, setContry] = useState(null);
    const [country2, setContry2] = useState(null);
    const [currency, setCurrency] = useState(null);


    const getAllCountry = async () => {
        const listUser = await axios.get(`https://nuggettrap.com/api/ekyc/country-list.php`)
        setCountryList(listUser.data.result)
    }
    const handleClose = (event, reason
    ) => {
        if (reason === 'clickaway') {
            return;
        }
        setSOpen(false);
    };
    const handleClose2 = (event, reason
    ) => {
        if (reason === 'clickaway') {
            return;
        }
        setEOpen(false);
    };
    const handalAdd = () => {
        if (cexcoin == '' || totalTokenList == '') {
            alert('Please enter CEX (Coin Exchange) or Total Token ')
            return
        }
        const listA = { cexcoin: cexcoin, totalTokenList: totalTokenList, id: Date.now() };
        setListingList(predata => [...predata, listA])
        setcexcoin(''); setTotalTokenList('');
    }
    const handalDel = async (delid) => {
        const newArray = await listingList.filter(item => item.id !== delid);
        setListingList(newArray);

    }
    const handalSubmit = async () => {
        if(!newTreasury.project_name || newTreasury.project_name=='' ){ return alert('Please enter project name') }
        if(!newTreasury.token_coin || newTreasury.token_coin=='' ){ return alert('Please enter Token/Coin Abbreviation') }
        if(!newTreasury.git_link || newTreasury.git_link=='' ){ return alert('Please enter project GitHub link') }
        if(!newTreasury.company_name || newTreasury.company_name=='' ){ return alert('Please enter company name') }
        if(!newTreasury.country || newTreasury.country=='' ){ return alert('Please select country name') }
        if(!newTreasury.f_name || newTreasury.f_name=='' ){ return alert('Please enter name') }
        if(!newTreasury.email || newTreasury.email=='' ){ return alert('Please enter email') }
        if(!newTreasury.phone || newTreasury.phone=='' ){ return alert('Please enter phone') }
        if(listingList.length ===0){ return alert('Please add LISTING EXCHANGE !') }
        setnewTreasury((prev)=>({...prev, listingList: JSON.stringify( listingList)}));
        console.log(listingList)
        console.log(newTreasury)
        setOpen(true)
        const addTreasury = await axios.post(`${process.env.REACT_APP_API_URL}/treasury/add-new`, newTreasury);
        setOpen(false)
        if(addTreasury.data.error==0){
            setMessage('Treasury successfully added! ')
            setSOpen(true); setListingList([])
            setnewTreasury({address:'',company_name:'',country:'',country_residence:'',currency_type:'', designation:'',email:'',f_name:'',git_link:'',l_name:'',listingList:'',max_amount_token_coin_wallet_address:'',phone:'',possibl_block_address:'',price_listing:'',price_ppm:'',project_name:'',token_coin:'',total_token_coin:''})
        }else{
            setMessage('Somthing was wrong! ')
            setEOpen(true);
        }

        /*
        setOpen(true) // open loading
        setTimeout(() => {
            setOpen(false) // close loading
            setMessage('Treasury successfully added! ')
            setEOpen(true); // open alert message
        }, 1000);
        */

    }
    useEffect(() => {
        getAllCountry();
    }, [])
    useEffect(() => {
        if (location.state) {
            setnewTreasury(location.state)
            setListingList(JSON.parse(location.state.listingList ) )
            //{id:"2", name: "Albania",phonecode:"355",sortname:"AL"}
            //setContry()
            setContry2(location.state.country_residence)
            setCurrency(location.state.currency_type)
            console.log(location.state)
        }
    }, [location])

    return (
        <div>
            <div className='box'>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" color="inherit" href="/">
                        Dashboard
                    </Link>
                    <Typography sx={{ color: 'text.primary' }}>List</Typography>
                </Breadcrumbs>
            </div>
            <div className='text-inner'>
                <Button variant="contained" color='primary'
                    onClick={() => { navigate('/dashboard/treasury-list') }}
                >List
                    <AiOutlineOrderedList size={20} />
                </Button>
                <Card style={{ padding: '10px' }}>

                    <form>
                        <h4>PROJECT DETAILS</h4>
                        <hr />
                        <Grid container spacing={1}>
                            <Grid size={3}>
                                <FormControl sx={{ m: 1, }} variant="outlined">
                                    <TextField
                                        label="Project name" size='small' InputLabelProps={{ shrink: true }}
                                        variant="outlined" value={newTreasury.project_name}
                                        onChange={(e) => { setnewTreasury((prev) => ({ ...prev, project_name: e.target.value })) }}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid size={3}>
                                <FormControl sx={{ m: 1, }} variant="outlined">
                                    <TextField
                                        label="Token/Coin Abbreviation " size='small' InputLabelProps={{ shrink: true }}
                                        variant="outlined" value={newTreasury.token_coin}
                                        onChange={(e) => { setnewTreasury((prev) => ({ ...prev, token_coin: e.target.value })) }}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid size={3}>
                                <FormControl sx={{ m: 1, }} variant="outlined">
                                    <TextField
                                        label="Project Contract Address" size='small' InputLabelProps={{ shrink: true }}
                                        variant="outlined" value={newTreasury.address}
                                        onChange={(e) => { setnewTreasury((prev) => ({ ...prev, address: e.target.value })) }}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid size={3}>
                                <FormControl sx={{ m: 1, }} variant="outlined">
                                    <TextField
                                        label="Project Github Link" size='small' InputLabelProps={{ shrink: true }}
                                        variant="outlined" value={newTreasury.git_link}
                                        onChange={(e) => { setnewTreasury((prev) => ({ ...prev, git_link: e.target.value })) }}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                        <h4>Company Details</h4>
                        <hr />
                        <Grid container spacing={1}>
                            <Grid size={3}>
                                <FormControl sx={{ m: 1, }} variant="outlined">
                                    <TextField
                                        label="Company Name" size='small' InputLabelProps={{ shrink: true }}
                                        variant="outlined" value={newTreasury.company_name}
                                        onChange={(e) => { setnewTreasury((prev) => ({ ...prev, company_name: e.target.value })) }}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid size={3}>
                                <FormControl sx={{ m: 1, }} variant="outlined">
                                    <Autocomplete
                                        disablePortal size='small' options={countryList} sx={{ width: 230 }}
                                        getOptionSelected={(option, value) => option.name === country}
                                        value={country}
                                        onChange={(e, val) => {
                                            setnewTreasury((prev) => ({ ...prev, country: val }))
                                            setContry(val)
                                            console.log(val)
                                            //handalGetState(val?.id)
                                        }}
                                        getOptionLabel={(option) => option.name}
                                        renderInput={(params) => <TextField {...params} label="Country" />}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid size={3}>
                                <FormControl sx={{ m: 1, }} variant="outlined">
                                    <TextField
                                        label="First Name" size='small' InputLabelProps={{ shrink: true }}
                                        variant="outlined" value={newTreasury.f_name}
                                        onChange={(e) => { setnewTreasury((prev) => ({ ...prev, f_name: e.target.value })) }}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid size={3}>
                                <FormControl sx={{ m: 1, }} variant="outlined">
                                    <TextField
                                        label="Last Name" size='small' InputLabelProps={{ shrink: true }}
                                        variant="outlined" value={newTreasury.l_name}
                                        onChange={(e) => { setnewTreasury((prev) => ({ ...prev, l_name: e.target.value })) }}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid size={3}>
                                <FormControl sx={{ m: 1, }} variant="outlined">
                                    <TextField
                                        label="Designation" size='small' InputLabelProps={{ shrink: true }}
                                        variant="outlined" value={newTreasury.designation}
                                        onChange={(e) => { setnewTreasury((prev) => ({ ...prev, designation: e.target.value })) }}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid size={3}>
                                <FormControl sx={{ m: 1, }} variant="outlined">
                                    <Autocomplete
                                        disablePortal size='small' options={countryList} sx={{ width: 230 }}
                                        value={country2}
                                        onChange={(e, val) => {
                                            setnewTreasury((prev) => ({ ...prev, country_residence: val?.name }))
                                            // handalGetState(val?.id)
                                            setContry2(val?.name)
                                        }}
                                        getOptionLabel={(option) => option.name}
                                        renderInput={(params) => <TextField {...params} label="Country of Residence" />}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid size={3}>
                                <FormControl sx={{ m: 1, }} variant="outlined">
                                    <TextField
                                        label="Email" size='small' InputLabelProps={{ shrink: true }}
                                        variant="outlined" value={newTreasury.email}
                                        onChange={(e) => { setnewTreasury((prev) => ({ ...prev, email: e.target.value })) }}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid size={3}>
                                <FormControl sx={{ m: 1, }} variant="outlined">
                                    <MuiPhoneNumber defaultCountry={'in'}
                                        variant="outlined" size='small' sx={{ width: 225 }}
                                        value={newTreasury.phone}
                                        onChange={(val) => { setnewTreasury((prev) => ({ ...prev, phone: val })) }}
                                        placeholder='Phone'
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                        <h4>TOKEN DETAILS</h4>
                        <hr />
                        <Grid container spacing={1}>
                            <Grid size={3}>
                                <FormControl sx={{ m: 1, }} variant="outlined">
                                    <TextField
                                        label="Total Treasury Token / Coins" size='small' InputLabelProps={{ shrink: true }}
                                        variant="outlined" value={newTreasury.total_token_coin}
                                        onChange={(e) => { setnewTreasury((prev) => ({ ...prev, total_token_coin: e.target.value })) }}
                                    />
                                </FormControl>
                            </Grid>
                            {/* ) */}
                            <Grid size={3}>
                                {/* Currency Type  value={cointVal} setcointVal(newval); */}
                                <FormControl sx={{ m: 1, }} variant="outlined">
                                    <Autocomplete
                                        disablePortal
                                        size='small'
                                        options={typecurrency}
                                        value={currency}
                                        sx={{ width: 230 }}
                                        onChange={(event, newval) => {
                                            setnewTreasury((prev) => ({ ...prev, currency_type: newval }));
                                            setCurrency(newval)
                                        }}
                                        renderInput={(params) => <TextField {...params} label="Currency type" />}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid size={3}>
                                <FormControl sx={{ m: 1, }} variant="outlined">
                                    <TextField
                                        label="Price (PPM)" size='small' InputLabelProps={{ shrink: true }}
                                        variant="outlined" value={newTreasury.price_ppm}
                                        onChange={(e) => { setnewTreasury((prev) => ({ ...prev, price_ppm: e.target.value })) }}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid size={3}>
                                <FormControl sx={{ m: 1, }} variant="outlined">
                                    <TextField
                                        label="Price (Listing)" size='small' InputLabelProps={{ shrink: true }}
                                        variant="outlined" value={newTreasury.price_listing}
                                        onChange={(e) => { setnewTreasury((prev) => ({ ...prev, price_listing: e.target.value })) }}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                        <h4>LISTING EXCHANGE</h4>
                        <hr />
                        <Grid container spacing={1}>
                            <Grid size={3}>
                                <FormControl sx={{ m: 1, }} variant="outlined">
                                    <TextField
                                        label="CEX (Coin Exchange)" size='small' InputLabelProps={{ shrink: true }}
                                        variant="outlined" value={cexcoin}
                                        onChange={(e) => { setcexcoin(e.target.value) }}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid size={3}>
                                <FormControl sx={{ m: 1, }} variant="outlined">
                                    <TextField
                                        label="Total Token Listed" size='small' InputLabelProps={{ shrink: true }}
                                        variant="outlined" value={totalTokenList}
                                        onChange={(e) => { setTotalTokenList(e.target.value) }}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid size={3}>
                                <FormControl sx={{ m: 1, }} variant="outlined">
                                    <Button variant="contained" color="success" onClick={handalAdd}>
                                        <MdAddCircle size={20} />
                                    </Button>
                                </FormControl>
                            </Grid>
                        </Grid>

                        {/* ********* */}
                        {listingList.length > 0 &&
                            <>
                                {listingList.map((list, i) => (
                                    <Grid container spacing={1} key={i}>
                                        <Grid size={3}>
                                            <FormControl sx={{ m: 1, }} variant="outlined">
                                                <TextField disabled
                                                    label="CEX (Coin Exchange)" size='small' InputLabelProps={{ shrink: true }}
                                                    variant="outlined" defaultValue={list.cexcoin}
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid size={3}>
                                            <FormControl sx={{ m: 1, }} variant="outlined">
                                                <TextField disabled
                                                    label="Total Token Listed" size='small' InputLabelProps={{ shrink: true }}
                                                    variant="outlined" defaultValue={list.totalTokenList}
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid size={3}>
                                            <FormControl sx={{ m: 1, }} variant="outlined">
                                                <Button variant="contained" color="error"
                                                    onClick={() => { handalDel(list.id) }}>
                                                    <MdDelete size={20} />
                                                </Button>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                ))}
                            </>

                        }
                        <hr />
                        {/*  */}
                        <Grid container spacing={1}>
                            <Grid size={5}>
                                <FormControl sx={{ m: 1, }} variant="outlined">
                                    <TextField sx={{ width: 450 }}
                                        label="Possibility to block Wallet Address" size='small' InputLabelProps={{ shrink: true }}
                                        variant="outlined" value={newTreasury.possibl_block_address}
                                        onChange={(e) => { setnewTreasury((prev) => ({ ...prev, possibl_block_address: e.target.value })) }}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid size={5}>
                                <FormControl sx={{ m: 1, }} variant="outlined">
                                    <TextField sx={{ width: 450 }}
                                        label="Maximum amount of tokens/coins on one wallet address" size='small' InputLabelProps={{ shrink: true }}
                                        variant="outlined" 
                                        value={newTreasury.max_amount_token_coin_wallet_address}
                                        onChange={(e) => { setnewTreasury((prev) => ({ ...prev, max_amount_token_coin_wallet_address: e.target.value })) }}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid size={12}>
                                <Button variant="contained" style={{ width: '100%' }}
                                    onClick={handalSubmit} >Submit <MdSave size={20} />
                                </Button>
                            </Grid>
                            {/* TREASURY */}
                        </Grid>
                        <Snackbar
                            open={sopen}
                            autoHideDuration={3000}
                            onClose={handleClose}
                            severity="success"
                            message={message}
                        >
                            <Alert severity="success">{message} </Alert>
                        </Snackbar>

                        <Snackbar
                            open={eopen}
                            autoHideDuration={3000}
                            onClose={handleClose2}
                            message={message}
                        >
                            <Alert severity="error">{message}</Alert>
                        </Snackbar>
                        
                        <Backdrop
                            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                            open={open}>
                            <CircularProgress color="inherit" />
                        </Backdrop>

                    </form>
                </Card>
            </div>
        </div>
    )
}

export default TreasuryNew